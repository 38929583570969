<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title" v-if="CanBeCancelled"
          >Refund Fulfilment - Step {{ currentStage + 1 }} of 2</span
        >
        <span class="modal-card-title" v-else
          >Refund Fulfilment - Not Available</span
        >
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section v-if="!CanBeCancelled" class="modal-card-body">
        <div>
          <p v-if="!CanContactCustomerServiceIfRequired">
            This order has been sent already to the buerau but we don't have
            contact details for them. Please escalate this ticket.
          </p>
          <p v-else>
            This order has a discount code applied to it, Please esaclate this
            request to the fulfilment team to process this cancellation.
          </p>
        </div>
      </section>
      <section v-else class="modal-card-body">
        <div v-if="currentStage === 0">
          <!-- <h3 class="title is-6">Step 1 - Set as Refund</h3> -->

          <div class="field">
            <label class="label">Refund Reason</label>
            <div class="control">
              <div class="select">
                <select v-model="selectedRefundReason">
                  <option :value="null">Please Select...</option>
                  <option v-for="c in refundReasonOptions" :value="c" :key="c">
                    {{ c }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field" v-if="selectedRefundReason === refundOtherString">
            <label class="label">Please Explain Reason:</label>
            <div class="control">
              <input
                v-model="refundReasonOtherMore"
                class="input"
                type="text"
              />
            </div>
          </div>
        </div>
        <!-- <div v-else-if="currentStage === 1">
          <ol style="list-style-position:inside">
            <li>
              Copy the row to your clipboard by
              <button
                class="button is-primary"
                v-on:click="copyRefundRowToClipboard()"
              >Copy to clipboard</button>
            </li>
            <li>
              Paste the order row into the
              <a
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1pIRliqClHBZ1u24emWYv4J14ZAkl6wV1kxn4wEPOYiU/edit?ts=5f0d92b2#gid=1086297484"
              >google refund sheet</a> table called "Refunds".
            </li>
          </ol>
          <textarea v-if="showRefundTable" id="RefundTableRow" v-model="refundRow"></textarea>
        </div>-->
        <div v-else-if="currentStage === 1">
          <!-- <h3 class="title is-6">Step 3 - Inform the customer</h3> -->

          <div v-if="DirectDebitNotSent">
            <h6 class="title is-6">
              Direct Debit - Has not been sent to bureau
            </h6>
            <p>
              <b>1.</b>Copy the below text in to your email reply to the
              customer in Freshdesk. Along with anything else necessary to
              personalise/make relevant to the customer's email then send.
            </p>

            <freshdesk-copy
              :fulfilment="fulfilment"
              type="DDNotSent"
              :hide-table="true"
            >
              <template v-slot:header>
                <p>Hi {{ customerReplyName }}</p>
                <br />
                <p>Thank you for your email.</p>
                <br />
                <p>
                  I can confirm that your order has been cancelled as requested,
                  and that no payment has been taken.
                </p>
              </template>
              <template v-slot:footer>
                <p>Please come back to us if you have any further issues</p>
                <br />
                <p>Best wishes</p>
              </template>
            </freshdesk-copy>
          </div>
          <div v-else-if="CreditCardNotSent">
            <h6 class="title is-6">
              Credit Card - Has not been sent to bureau
            </h6>
            <p>
              <b>1.</b>Copy the below text in to your email reply to the
              customer in Freshdesk. Along with anything else necessary to
              personalise/ make relevant to the customer's email then send.
            </p>

            <freshdesk-copy
              :fulfilment="fulfilment"
              type="CCNotSent"
              :hide-table="true"
            >
              <template v-slot:header>
                <p>Hi {{ customerReplyName }}</p>
                <br />
                <p>Thank you for your email.</p>
                <br />
                <p>
                  I can confirm that your order has been cancelled as requested,
                  and that a refund will be processed via your original payment
                  method within 3 working days.
                </p>
              </template>
              <template v-slot:footer>
                <p>Please come back to us if you have any further issues</p>
                <br />
                <p>Best wishes</p>
              </template>
            </freshdesk-copy>
          </div>
          <div v-else-if="DirectDebitAlreadySent">
            <p>
              <b>1.</b> Add the below customer service contact to the CC of your
              reply to the customer in Freshdesk
            </p>
            <contact-table
              :contact="this.fulfilment.CustomerServiceContacts[0]"
              :show-copy="true"
            ></contact-table>
            <p>
              <b>2.</b> Copy the below text in to your email reply to the
              customer in Freshdesk. Along with anything else necessary to
              personalise/ make relevant to the customer's email then send.
            </p>
            <freshdesk-copy :fulfilment="fulfilment" type="DDSent">
              <template v-slot:header>
                <p>Hi {{ customerReplyName }}</p>
                <br />
                <p>Thank you for your email.</p>
                <br />
                <p>
                  As your order has already been sent over to the publisher, I
                  have copied them in my email so that they can action a
                  cancellation. They are also handling the direct debit payment,
                  so they will be able to cancel/refund this where necessary. If
                  a direct debit has already been set up, it may be worth
                  contacting your bank to ensure that no further payments are
                  taken.
                </p>
                <br />
                <p>
                  For the publisher's reference, we can confirm the following
                  order details:
                </p>
              </template>
              <template v-slot:footer>
                <p>
                  For any future queries please contact the publisher directly
                  at:
                  <br />
                  {{ GetCustomerServicesEmail }}
                </p>
                <br />
                <p>Best wishes</p>
              </template>
            </freshdesk-copy>
          </div>
          <div v-else-if="CreditCardAlreadySent">
            <h6 class="title is-6">Credit Card - Has been sent to bureau</h6>
            <p>
              <b>1.</b> Add the below customer service contact to the CC of your
              reply to the customer in Freshdesk
            </p>
            <contact-table
              :contact="this.fulfilment.CustomerServiceContacts[0]"
              :show-copy="true"
            ></contact-table>
            <p>
              <b>2.</b> Copy the below text in to your email reply to the
              customer in Freshdesk. Along with anything else necessary to
              personalise/make relevant to the customer's email then send.
            </p>
            <freshdesk-copy
              :fulfilment="fulfilment"
              type="CCSent"
              v-if="ConnectToProcessRefunds"
            >
              <template v-slot:header>
                <p>Hi {{ customerReplyName }}</p>
                <br />
                <p>
                  I can confirm that your order has now been cancelled and will
                  be refunded to your account within 3 working days.
                </p>
                <br />
                <p>
                  As your order has already been sent to the publisher, I have
                  copied them in my email so that they are aware of the
                  cancellation. They should be able to confirm this with you
                  directly.
                </p>
                <br />
                <p>
                  For the publisher's reference, we can confirm the following
                  order details:
                </p>
              </template>
              <template v-slot:footer>
                <p>
                  For any future queries please contact the publisher directly:
                  <br />
                  {{ GetCustomerServicesEmail }}
                </p>
                <br />
                <p>Best wishes</p>
              </template>
            </freshdesk-copy>

            <freshdesk-copy :fulfilment="fulfilment" type="CCSent" v-else>
              <template v-slot:header>
                <p>Hi {{ customerReplyName }}</p>
                <br />
                <p>
                  As your order has already been sent to the publisher, I have
                  copied them in my email as they handle all cancellation and
                  refund requests directly.
                </p>
                <br />
                <p>
                  For the publisher's reference, we can confirm the following
                  order details:
                </p>
              </template>
              <template v-slot:footer>
                <p>
                  For any future queries please contact the publisher directly:
                  <br />
                  {{ GetCustomerServicesEmail }}
                </p>
                <br />
                <p>Best wishes</p>
              </template>
            </freshdesk-copy>
          </div>

          <br />
          <p>
            <b-checkbox
              id="hasContactedCustomerCheckbox"
              v-model="hasContactedCustomer"
            >
              {{
                IsOrderSent
                  ? "I have sent the above email and CC'ed in customer service contact via freshdesk"
                  : "I have sent the above email via freshdesk"
              }}
            </b-checkbox>
          </p>
        </div>
      </section>

      <footer class="modal-card-foot" v-if="CanBeCancelled">
        <button
          class="button"
          v-if="currentStage > 1"
          v-on:click="currentStage--"
        >
          Back Step
        </button>
        <b-button
          class="is-success"
          v-if="currentStage === 0"
          :disabled="!HasSelectedReason"
          v-on:click="cancelFulfilmentConfirm"
          :loading="isLoading"
          :title="HasSelectedReason ? '' : 'Select a reason for the refund'"
          >Cancel Fulfilment &amp; proceed to step 2</b-button
        >
        <!-- <button
          class="button is-success"
          v-else-if="currentStage === 1"
          v-on:click="copyToRefundSheet"
          :disabled="!hasCopiedRowToClipboard"
          :title="hasCopiedRowToClipboard ? '' : 'Copy to clipboard before you can proceed'"
        >Proceed to step 3</button>-->
        <button
          class="button is-success"
          :disabled="!hasContactedCustomer"
          :title="
            hasContactedCustomer
              ? ''
              : 'Confirm you have contacted customer with the checkbox'
          "
          v-else-if="currentStage === 1"
          v-on:click="contactedCustomerAndPublisher"
        >
          Finished
        </button>
        <!-- <button class="button" v-on:click="close">{{ readOnly ? "Close" : "Cancel" }}</button> -->
      </footer>

      <footer class="modal-card-foot" v-else>
        <button class="button is-success" v-on:click="Close">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
//fulfilment-info-for-bureau
export default {
  name: "fulfilmentCancelFlowDialog",
  mixins: [baseMixin],
  //canCancel
  components: {
    contactTable: () => import("../../components/molecules/contactTable.vue"),
    freshdeskCopy: () => import("../../components/molecules/freshdeskCopy.vue"),
  },
  props: {
    fulfilment: Object,
  },
  data() {
    var refundOtherStringConst = "Other";

    return {
      currentStage: 0, //skip the cancel stage if they can
      refundOtherString: refundOtherStringConst,
      refundReasonOptions: [
        "Non Delivery",
        "No Longer wants",
        "Fraudulent order",
        "Duplicate Order",
        "Accidentally ordered incorrect title",
        "Unwanted gift",
        "Cancelled with publisher",
        "Overseas order",
        refundOtherStringConst,
      ],
      selectedRefundReason: null,
      refundReasonOtherMore: "",
      showRefundTable: false,
      hasCopiedRowToClipboard: false,
      hasContactedCustomer: false,
      isLoading: false,
      // isLoading: false,
      // tempContactGroup: this.contactGroup,
      APIUrl: process.env.VUE_APP_APIURL,
    };
  },
  created: function () {
    var canSkipFirstStep = this.fulfilment.Status === 4 && this.CanBeCancelled; //cancelled and can be cancelled
    this.currentStage = canSkipFirstStep ? 1 : 0; //skip the cancel stage if they can
  },
  methods: {
    Close: function () {
      //emit close!
      this.$emit("close");
    },
    cancelFulfilmentConfirm() {
      var msg =
        "Are you sure you want to cancel fulfilment order<br><br>" +
        "<p><b>The fulfilment order will be marked as cancelled within fulfilment</b></p>";
      var title = "Cancel fulfilment";

      this.$dialog.confirm({
        title: title,
        message: msg,
        onConfirm: () => this.cancelFulfilment(),
      });
    },
    cancelFulfilment() {
      this.isLoading = true;
      var url =
        this.APIUrl +
        "/fulfilment/bulk/cancel?notes=" +
        this.cancelReason +
        "&id=" +
        this.fulfilment.Id +
        "&addToRefundSheet=true";
      var that = this;

      this.axios.put(url).then((response) => {
        that.$emit("fulfilmentUpdate", response.data);
        that.$toast.open(
          "Cancelled Fulfilment, it will be not be sent, Reason: " +
            that.cancelReason
        );
        that.currentStage++;
        this.isLoading = false;
      });
    },
    copyToRefundSheet() {
      this.currentStage++;
    },
    contactedCustomerAndPublisher() {
      this.Close();
    },
    copyRefundRowToClipboard() {
      this.hasCopiedRowToClipboard = true;
      this.showRefundTable = true;

      this.$nextTick(() => {
        this.copyToClipboard("RefundTableRow");
        this.showRefundTable = false;
      });
    },
  },
  computed: {
    ConnectToProcessRefunds() {
      //We want bauer/CDS to process themselves
      return this.fulfilment.BatchJobInfo.RefundsHandledByConnect;
    },
    RefundType: function () {
      var hassent = this.IsOrderSent ? "Has Sent" : "Not Sent";
      var paymentType =
        this.fulfilment.Data.products_info.payment_method === "DD"
          ? "Direct Debit"
          : "Credit Card";
      return paymentType + " - " + hassent;
    },
    DirectDebitAlreadySent() {
      return (
        this.IsOrderSent &&
        this.fulfilment.Data.products_info.payment_method === "DD"
      );
    },
    DirectDebitNotSent() {
      return (
        !this.IsOrderSent &&
        this.fulfilment.Data.products_info.payment_method === "DD"
      );
    },
    CreditCardAlreadySent() {
      return (
        this.IsOrderSent &&
        this.fulfilment.Data.products_info.payment_method === "CC"
      );
    },
    CreditCardNotSent() {
      return (
        !this.IsOrderSent &&
        this.fulfilment.Data.products_info.payment_method === "CC"
      );
    },

    IsOrderSent() {
      return (
        this.fulfilment.BatchJobInfo !== null &&
        this.fulfilment.BatchJobInfo.StatusAsString == "Sent"
      );
    },
    TodayDate() {
      var y = new Date().getFullYear();
      var m = new Date().getMonth() + 1;
      var d = new Date().getDate();
      return y + "-" + m + "-" + d;
    },
    PaymentMethod() {
      //Credit / Debit Card / Paypal
      if (this.fulfilment.Data.products_info.payment_method === "DD")
        return "Direct Debit";
      else return this.fulfilment.Data.payment_info.CC.card_type;
    },
    TransactionDateOnly() {
      return this.fulfilment.Data.meta_info.transaction_datetime.split(" ")[0];
    },
    HasDiscountApplied() {
      return (
        //has discount code OR discount amount
        (this.fulfilment.Data.other.discount_code != null &&
          this.fulfilment.Data.other.discount_code.length > 0) ||
        (this.fulfilment.Data.other.discount_amount != null &&
          this.fulfilment.Data.other.discount_amount.length > 0)
      );
    },
    CanContactCustomerServiceIfRequired() {
      return (
        !this.IsOrderSent ||
        (this.fulfilment.CustomerServiceContacts !== null &&
          this.fulfilment.CustomerServiceContacts.length > 0 &&
          this.fulfilment.CustomerServiceContacts[0].Email !== null &&
          this.fulfilment.CustomerServiceContacts[0].Email.length > 0)
      );
    },
    CanBeCancelled() {
      //no discount applied, is jmag etc
      return (
        !this.HasDiscountApplied && this.CanContactCustomerServiceIfRequired
      ); //&& this.fulfilment.other.is_JMAG == 1;
    },
    GetCustomerServicesEmail() {
      return this.fulfilment.CustomerServiceContacts[0].Email;
    },
    GetCustomerServiceNameOrEmail() {
      var name = this.fulfilment.CustomerServiceContacts[0].Name;
      return name !== null && name.length > 0
        ? name
        : this.GetCustomerServicesEmail;
    },
    cancelReason() {
      var reason =
        this.selectedRefundReason === this.refundOtherString
          ? this.refundReasonOtherMore
          : this.selectedRefundReason;

      return reason === null ? "" : reason;
    },
    HasSelectedReason() {
      return this.cancelReason.length > 0;
    },
    customerReplyName() {
      return (
        this.fulfilment.Data.primary_contact.primary_contact_title +
        " " +
        this.fulfilment.Data.primary_contact.primary_contact_first_name +
        " " +
        this.fulfilment.Data.primary_contact.primary_contact_last_name
      );
    },
    refundRow() {
      return (
        this.fulfilment.Data.primary_contact.primary_contact_first_name +
        "\t" +
        this.fulfilment.Data.primary_contact.primary_contact_last_name +
        "\t" +
        this.fulfilment.Data.primary_contact.primary_contact_email +
        "\t" +
        this.fulfilment.ProductName +
        "\t" +
        this.TransactionDateOnly +
        "\t" +
        (this.fulfilment.Data.other.VendorTxCode
          ? this.fulfilment.Data.other.VendorTxCode
          : this.fulfilment.Data.meta_info.transaction_ref) +
        "\t" +
        this.fulfilment.Data.products_info.offer_price +
        "\t" +
        "0.00" +
        "\t" +
        this.fulfilment.Data.products_info.offer_price +
        "\t" +
        this.TodayDate +
        "\t" +
        this.fulfilment.FourLetterCode +
        "\t" +
        this.fulfilment.PublisherCode +
        "\t" +
        this.Refund +
        "\t" +
        this.PaymentMethod +
        "\t" +
        this.cancelReason
      );
    },
  },
};
</script>
